import React from 'react';
import ReactDOM from 'react-dom/client'; // Use React 18's createRoot API.
import { setupIonicReact } from '@ionic/react';
import App from './App';

// Import Ionic CSS globally.
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/flex-utils.css';

import '@ionic/react/css/display.css';         // Display utilities


import './theme/variables.css';
import './theme/global.css';


setupIonicReact(); // Initialize Ionic React.

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container); // Use createRoot instead of ReactDOM.render.
root.render(<App />);
