import React from 'react';
import { IonCard, IonCardContent, IonButton, IonIcon, IonSpinner, IonCol } from '@ionic/react';
import { downloadOutline, trashOutline } from 'ionicons/icons';

const ClipDisplay = ({ clipURL, isProcessing, onDelete }) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = clipURL;
    link.download = 'clip.mp4';
    link.click();
    URL.revokeObjectURL(clipURL); // Clean up the object URL
  };

  return (
    <IonCol size="2.5">
    <IonCard>
      <IonCardContent >
        {isProcessing ? (
          <div style={{ textAlign: 'center' }}>
            <IonSpinner name="dots" style={{ color: 'var(--ion-color-primary)' }} />
            <p>Processing Clip...</p>
          </div>
        ) : (
          <video
            src={clipURL}
            autoPlay
            muted
            loop
            controls
            style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
          />
        )}
      </IonCardContent>

      {!isProcessing && (
        <div style={{ display: 'flex', justifyContent: 'space-around', margin: '1rem' }}>
          <IonButton onClick={handleDownload}>
            <IonIcon icon={downloadOutline} slot="start" />
            Download
          </IonButton>

          {false && <IonButton color="danger" onClick={onDelete}>
            <IonIcon icon={trashOutline} slot="start" />
            Delete
          </IonButton>
          }
        </div>
      )}
    </IonCard>
    </IonCol>
  );
};

export default ClipDisplay;
